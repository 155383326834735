import React from "react"

export default function NotFound() {
  return (
    <div>
      <h1>404</h1>

      <p>Sorry, this page doesn't exist</p>
    </div>
  )
}
